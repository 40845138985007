


















































































































































import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import Update from '@/mixins/update';

@Component({
  computed: {
    ...mapGetters('Account', ['booking',
      'isTokenSet',
    ]),
    ...mapGetters('Bookings', ['step']),
  },
})
export default class extends mixins(Update) {
  readonly step!: number;

  isTokenSet!: boolean;

  get year() {
    const date = new Date();
    return date.getFullYear();
  }

  logout() {
    this.$store.commit('Account/logout');
    if (!this.isTokenSet) {
      this.$router.push('/login');
    }
  }

  @Watch('step')
  onStepChange() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
