













































































import { Reservation } from 'tigerbay/lib/models/reservations';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

// eslint-disable-next-line import/no-cycle
import components from '@/components/account';

@Component({
  components,

  computed: {
    ...mapGetters('Account', [
      'components',
    ]),
  },
  methods: {
    flightItem(text: string) {
      return text.replace('&gt;', '>');
    },
  },
})
export default class Summary extends Vue {
  @Prop() booking!: Reservation;
}
