























import { Note as NoteModel } from 'tigerbay/lib/models/notes';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Note extends Vue {
  @Prop() note!: NoteModel;
}
