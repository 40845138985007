









import { Payment } from 'tigerbay/lib/models/payments';
import { Price } from 'tigerbay/lib/models/reservations';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class NewPayment extends Vue {
  @Prop() outstanding!: Price;

  public amount = this.outstanding.Value;

  async submit() {
    const payment: Payment = await this.$store.dispatch('Account/pay', { amount: this.amount });

    if (payment.Url && window) {
      window.location.href = payment.Url;
    }
  }
}
