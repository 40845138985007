













































































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapMutations } from 'vuex';
import { BIcon, BIconCheckCircle, BIconExclamationCircleFill } from 'bootstrap-vue';
import PassengerRoomSelection from './PassengerRoomSelection.vue';
import PrevStep from '@/components/booking/PrevStep.vue';
import NextStep from '@/components/booking/NextStep.vue';

@Component({
  name: 'rooming-assignment',
  computed: {
    ...mapGetters('Bookings', ['passengers', 'accommodationRooms', 'rooms', 'roomingValid']),
  },
  components: {
    PrevStep,
    NextStep,
    PassengerRoomSelection,
    BIcon,
    BIconCheckCircle,
    BIconExclamationCircleFill,
  },
  methods: {
    ...mapMutations('Bookings', ['nextStep']),
  },
})
export default class RoomAssignment extends Vue {
  readonly roomingValid!: boolean
}
