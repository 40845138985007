import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import BookingFailure from '@/views/BookingFailure.vue';
import Home from '../views/Home.vue';

import Book from '../views/Book.vue';
import PayBooking from '../views/PayBooking.vue';
import BookingConfirmation from '../views/BookingConfirmation.vue';

import TourSearch from '../views/TourSearch.vue';
import TourDetails from '../views/TourDetails.vue';
import Login from '../views/account/Login.vue';
import MyBooking from '../views/account/Booking.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
  },

  {
    path: '/my-booking/:reference',
    name: 'MyBooking',
    component: MyBooking,
  },

  {
    path: '/book/:id/payment',
    name: 'BookingPayment',
    component: PayBooking,
  },

  {
    path: '/book/:id/confirmation',
    name: 'BookingConfirmation',
    component: BookingConfirmation,
  },

  {
    path: '/book/:id/commiseration',
    name: 'BookingFailure',
    component: BookingFailure,
  },

  {
    path: '/book',
    name: 'Book',
    component: Book,
  },

  {
    path: '/tours/:id',
    name: 'TourDetails',
    component: TourDetails,
  },

  {
    path: '/tours',
    name: 'TourSearch',
    component: TourSearch,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
