



















































































































































































































































































import { Customer as CustomerModel } from 'tigerbay/lib/models/customers';
import { Reservation, Passenger as PassengerModel } from 'tigerbay/lib/models/reservations';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import components from '@/components/account';

@Component({
  components,
  computed: {
    ...mapGetters('Account', ['booking',
      'customer',
      'components',
      'passengers',
      'notes',
      'payments',
      'lastUpdated',
      'isTokenSet',
    ]),
    validPayments() {
      const results = [];
      const payment = this.$store.state.Account.payments;
      for (let i = 0; i < payment.length; i += 1) {
        if (payment[i].Status !== '0') {
          results.push(payment[i]);
        }
      }
      return results;
    },
    orderNotes() {
      const note = this.$store.state.Account.notes;
      note.reverse();
      return note;
    },
  },
  methods: {
    scrollIntoView(target) {
      const element: any = this.$refs[target];
      const top = element.offsetTop + 100;
      window.scrollTo({
        top,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
})
export default class Booking extends Vue {
  tabIndex = 0

  isTokenSet!: boolean;

  booking!: Reservation;

  customer!: CustomerModel | null;

  passengers!: PassengerModel[] | null;

  lastUpdated!: number | null;

  hideAdminPanel = false

  online = false;

  get startDate() {
    return new Date(this.booking?.StartDate);
  }

  mounted() {
    if (!this.isTokenSet) {
      this.$router.push('/login');
    }

    this.online = window.navigator.onLine;

    window.addEventListener('online', this.goOnline);
    window.addEventListener('offline', this.goOffline);

    if ('confirmPayment' in this.$route.query) {
      this.$store.dispatch('Account/confirmPayment').then(() => {
        this.$store.dispatch('Account/refresh');
      });
    } else {
      this.$store.dispatch('Account/refresh');
    }
  }

  goOnline() {
    this.online = true;
  }

  goOffline() {
    this.online = false;
  }

  beforeDestroy() {
    window.removeEventListener('online', this.goOnline);
    window.removeEventListener('offline', this.goOffline);
  }
}
