import axios, {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import QueryString from 'qs';
import { Models } from 'tigerbay';
import { CacheSearchRequest } from 'tigerbay/lib/models/cache';
import { CreateCustomerRequest, Customer, CustomerContact } from 'tigerbay/lib/models/customers';
import { Note, NoteType } from 'tigerbay/lib/models/notes';
import { Payment } from 'tigerbay/lib/models/payments';
import {
  BookingComponentListEntry, Passenger, Reservation,
  ReservationUpdateOperation, ServiceAssignmentRequest,
} from 'tigerbay/lib/models/reservations';

export interface InitializeBookingResponse {
  id: number;
  token: string;
  expiresIn: number;
}

export interface LoginResponse {
  token: string;
  booking: Models.Reservations.Reservation;
  customer: Models.Customers.Customer;
  components: Models.Reservations.BookingComponentListEntry[];
}

export default class API {
  private axios: AxiosInstance;

  public token?: string;

  constructor() {
    this.axios = axios.create();
    this.axios.defaults.paramsSerializer = JSON.stringify;
    this.axios.defaults.responseType = 'json';
    this.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    this.axios.interceptors.request.use((request: AxiosRequestConfig) => {
      if (this.token) {
        // eslint-disable-next-line no-param-reassign
        request.headers = request.headers || {};
        // eslint-disable-next-line no-param-reassign
        request.headers.Authorization = `Bearer ${this.token}`;
      }

      return request;
    });

    this.axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: unknown) => {
        if (typeof error === 'object' && error !== null && Object.keys(error).includes('response')) {
          document.dispatchEvent(new CustomEvent('backendError', { detail: error as AxiosError }));
        }
      },
    );
  }

  public async initializeBooking(): Promise<InitializeBookingResponse> {
    const { data } = await this.axios.post<InitializeBookingResponse>('/reservations');
    return data;
  }

  public async getBooking(id: number): Promise<Reservation> {
    return (await this.axios.get<Reservation>(`/reservations/${id}`)).data;
  }

  public async availabilitySearch(
    bookingId: number,
    tourCode: string,
    departureDate: Date,
  ): Promise<string> {
    return (await this.axios.post(
      `/reservations/${bookingId}/search`,
      { tourCode, departureDate },
    )).data.id;
  }

  public async getDepartures(bookingId: number, searchId: string):
    Promise<Array<Models.Tours.Departure>> {
    return (await this.axios.get(`/reservations/${bookingId}/search/${searchId}/departures`)).data;
  }

  public async getAccommodation(bookingId: number, searchId: string, departureId: string):
  Promise<Array<Models.Tours.AccommodationUnit>> {
    return (await this.axios.get(`/reservations/${bookingId}/search/${searchId}/departures/${departureId}/accommodation`)).data;
  }

  public async getExtras(bookingId: number, searchId: string, departureId: string):
  Promise<Models.Tours.TourExtra[]> {
    return (await this.axios.get(`/reservations/${bookingId}/search/${searchId}/departures/${departureId}/extras`)).data;
  }

  public async getFlights(bookingId: number, searchId: string, departureId: string):
  Promise<Models.Tours.Flight[]> {
    return (await this.axios.get(`/reservations/${bookingId}/search/${searchId}/departures/${departureId}/flights`)).data;
  }

  public async createPassenger(bookingId: number, req: Models.Reservations.AddPassengerRequest):
    Promise<Models.Reservations.Passenger & Models.Reservations.AddPassengerRequest> {
    return (await this.axios.post(`/reservations/${bookingId}/passengers`, req)).data;
  }

  public async updatePassenger(bookingId: number, passengerId: number,
    updates: ReservationUpdateOperation[]) {
    return (await this.axios.patch(`/reservations/${bookingId}/passengers/${passengerId}`, updates)).data;
  }

  public async assignPassengers(bookingId: number, searchId: string, resultId: string,
    assignments: ServiceAssignmentRequest) {
    return (await this.axios.put(`/reservations/${bookingId}/search/${searchId}/departures/${resultId}/passengers`, assignments)).data;
  }

  public async bookTour(bookingId: number, departureId: string) {
    return (await this.axios.put(`/reservations/${bookingId}/tour`, { departureId })).data;
  }

  public async setupCustomer(
    bookingId: number,
    params: { contact: CustomerContact; customer: CreateCustomerRequest },
  ): Promise<{ customer: Customer; contact: Models.Customers.CustomerContactResponse }> {
    return (await this.axios.post(`/reservations/${bookingId}/customer`, params)).data;
  }

  public async createPayment(
    bookingId: number,
    type: 'deposit' | 'full',
    contactId: number,
  ): Promise<Models.Payments.Payment> {
    const req = { type, contactId };

    return (await this.axios.post(`/reservations/${bookingId}/payment`, req)).data;
  }

  public async getPayment(bookingId: number, id: number): Promise<Models.Payments.Payment> {
    return (await this.axios.get(`/reservations/${bookingId}/payment/${id}`)).data;
  }

  public async confirmBooking(bookingId: number): Promise<{ error?: string }> {
    return (await this.axios.post(`/reservations/${bookingId}/confirm`, null, {
      validateStatus: (status) => status < 400,
    })).data;
  }

  public async finalizePayment(bookingId: number, paymentId: number): Promise<void> {
    await this.axios.post(`/reservations/${bookingId}/payment/${paymentId}/complete`);
  }

  public async searchTours(params: CacheSearchRequest): Promise<Models.Cache.Departure[]> {
    const query = QueryString.stringify(params);
    return (await this.axios.get(`/tours/search?${query}`)).data;
  }

  public async getTour(id: string): Promise<Models.Cache.Departure> {
    return (await this.axios.get(`/tours/${id}`)).data;
  }

  public async login(
    bookingReference: string,
    email: string,
    name: string,
  ): Promise<LoginResponse> {
    const params = {
      bookingReference,
      email,
      name,
    };

    return (await this.axios.post('/account/login', params)).data;
  }

  public async getUserBooking(): Promise<Reservation> {
    return (await this.axios.get<Reservation>('/account/booking')).data;
  }

  public async getUserProfile(): Promise<Customer> {
    return (await this.axios.get('/account/profile')).data;
  }

  public async getBookingComponents(): Promise<BookingComponentListEntry[]> {
    return (await this.axios.get('/account/components')).data;
  }

  public async getBookingPassengers(): Promise<Passenger[]> {
    return (await this.axios.get('/account/passengers')).data;
  }

  public async getBookingNotes(): Promise<Note[]> {
    return (await this.axios.get('/account/notes')).data;
  }

  public async addBookingNote(title: string, text: string): Promise<Note> {
    return (await this.axios.post('/account/notes', { title, text })).data;
  }

  public async addPassengerNote(
    bookingId: number,
    passengerId: number,
    type: NoteType,
    title: string,
    text: string,
  ): Promise<Note> {
    return (await this.axios.post(`/reservations/${bookingId}/passengers/${passengerId}/notes`, { type, title, text })).data;
  }

  public async getBookingPayments(): Promise<Payment[]> {
    return (await this.axios.get('/account/payments')).data;
  }

  public async addBookingPayment(amount: number): Promise<Payment> {
    return (await this.axios.post('/account/payments', { amount })).data;
  }

  public async listComponents(bookingId: number): Promise<BookingComponentListEntry[]> {
    return (await this.axios.get(`/reservations/${bookingId}/components`)).data;
  }
}
