import { render, staticRenderFns } from "./BookingFailure.vue?vue&type=template&id=633b44c4&scoped=true&"
import script from "./BookingFailure.vue?vue&type=script&lang=ts&"
export * from "./BookingFailure.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "633b44c4",
  null
  
)

export default component.exports