






















import { Passenger as PassengerModel } from 'tigerbay/lib/models/reservations';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class Passenger extends Vue {
  @Prop() passenger!: PassengerModel;
}
