import { Customer } from 'tigerbay/lib/models/customers';
import { Note } from 'tigerbay/lib/models/notes';
import { Payment } from 'tigerbay/lib/models/payments';
import { BookingComponentListEntry, Passenger, Reservation } from 'tigerbay/lib/models/reservations';
import AccountState from './state';

const isTokenSet = (state: AccountState): boolean => state.token.length > 0;
const booking = (state: AccountState): Reservation | null => state.booking;
const customer = (state: AccountState): Customer | null => state.customer;
const components = (state: AccountState): BookingComponentListEntry[] => state.components;
const passengers = (state: AccountState): Passenger[] => state.passengers;
const notes = (state: AccountState): Note[] => state.notes;
const payments = (state: AccountState): Payment[] => state.payments;
const lastUpdated = (state: AccountState): number | null => state.lastUpdated;

export default {
  isTokenSet,
  booking,
  customer,
  components,
  passengers,
  notes,
  payments,
  lastUpdated,
};
