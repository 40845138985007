import { sortBy } from 'lodash';
import { Models } from 'tigerbay';
import { MutationTree } from 'vuex';

export class ToursState {
  public tours: Models.Cache.Departure[];

  public stats: Models.Cache.CacheStats;

  constructor() {
    this.stats = { ItemCount: 0, AverageAgeSeconds: 0 };
    this.tours = [];
  }
}

export type TourSortProperty = 'Id' | 'StartDate' | 'Code' | 'EndDate' | 'Duration' |
                               'Name' | 'Generated';

const state = new ToursState();

const mutations: MutationTree<ToursState> = {
  setTours: (state: ToursState, payload: Models.Cache.Departure[]) => {
    state.tours = payload;
  },
};

const actions = {};

const getters = {
  tours: (state: ToursState) => (property: TourSortProperty) => sortBy(state.tours, property),
  count: (state: ToursState) => state.tours.length,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
