import Cookies from 'js-cookie';
import { Note } from 'tigerbay/lib/models/notes';
import { Payment } from 'tigerbay/lib/models/payments';
import API from '@/lib/api';

import AccountState from './state';

const api = new API();

async function login(
  { commit }: { commit: Function },
  { email, bookingReference, name }: {email: string; bookingReference: string; name: string},
): Promise<boolean> {
  const resp = await api.login(bookingReference, email, name);

  api.token = resp.token;

  commit('setBooking', resp.booking);
  commit('setCustomer', resp.customer);
  commit('setToken', resp.token);

  // Store the token in a cookie so we can utilize it from a service worker
  Cookies.set('xhfAuthToken', resp.token, { expires: 30, sameSite: 'Strict' });

  const components = await api.getBookingComponents();
  commit('setComponents', components);

  const passengers = await api.getBookingPassengers();
  commit('setPassengers', passengers);

  const notes = await api.getBookingNotes();
  notes.forEach((note: Note) => commit('addNote', note));

  return true;
}

async function refresh(
  { commit, state }: { commit: Function; state: AccountState },
) {
  if (state.token && !api.token) api.token = state.token;

  api.getUserProfile().then((customer) => commit('setCustomer', customer));
  api.getBookingComponents().then((components) => commit('setComponents', components));
  api.getBookingPassengers().then((passengers) => commit('setPassengers', passengers));
  api.getBookingNotes().then((notes) => commit('setNotes', notes));
  api.getUserBooking().then((booking) => commit('setBooking', booking));
  api.getBookingPayments().then((payments) => commit('setPayments', payments));
}

async function addNote(
  { commit, state }: { commit: Function; state: AccountState },
  { title, text }: { title: string; text: string },
): Promise<Note> {
  if (state.token && !api.token) api.token = state.token;

  const note = await api.addBookingNote(title, text);

  commit('addNote', note);

  return note;
}

async function pay(
  { commit, state }: { commit: Function; state: AccountState },
  { amount }: { amount: number },
): Promise<Payment> {
  if (state.token && !api.token) api.token = state.token;

  const payment = await api.addBookingPayment(amount);

  commit('setCurrentPaymentId', payment.Id);

  return payment;
}

async function confirmPayment(
  { commit, state }: { commit: Function; state: AccountState },
): Promise<void> {
  const { currentPaymentId, booking } = state;

  if (currentPaymentId === null || booking === null) return;

  if (!api.token) api.token = state.token;

  await api.finalizePayment(booking.Id, currentPaymentId);

  commit('setCurrentPaymentId', null);
}

export default {
  login,
  refresh,
  addNote,
  pay,
  confirmPayment,
};
