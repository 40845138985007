






































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Models } from 'tigerbay';

@Component({
  methods: {
    flightItem(text: string) {
      return text.replace('&gt;', '>');
    },
  },
})
export default class BookedItem extends Vue {
  @Prop() component!: Models.Reservations.BookingComponentListEntry[];
}
