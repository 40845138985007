









import Vue from 'vue';
import Component from 'vue-class-component';
import { mapMutations } from 'vuex';

@Component({
  name: 'prev-step',

  methods: {
    ...mapMutations('Bookings', ['prevStep']),
  },
})
export default class PrevStep extends Vue {}
