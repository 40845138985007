



































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
})
export default class NewNote extends Vue {
  @Prop() online!: boolean;

  title = '';

  text = '';

  async createNote() {
    const note = await this.$store.dispatch('Account/addNote', { title: this.title, text: this.text });

    this.title = '';
    this.text = '';

    this.$emit('noteCreated', note);
  }
}
