





















import { Customer as CustomerModel } from 'tigerbay/lib/models/customers';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class Customer extends Vue {
  @Prop() customer!: CustomerModel;
}
