import {
  flatten, map, flatMap, sum,
} from 'lodash';
import { PassengerAssignment } from 'tigerbay/lib/models/common';
import { PassengerType } from 'tigerbay/lib/models/reservations';
import { AccommodationUnit, TourExtra } from 'tigerbay/lib/models/tours';
import BookingState from './state';

/**
 * Convert rooming counts into a list of rooms
 *
 * @param state Booking State
 */
export function getRooms(state: BookingState): string[] {
  return flatten(map(
    state.roomCounts, (count, id) => Array(count).fill(id),
  ));
}

/**
 * Use the room counts and passenger mapping to generate roomning data
 *
 * @param state Booking State
 */
export function getRooming(state: BookingState): Array<PassengerAssignment> {
  const roomPax: Array<PassengerAssignment> = [];
  const rooms = getRooms(state);

  Object.values(state.passengerRoomSelection).forEach((selections: Record<number, number>) => {
    map(selections, (roomIdx: number, paxId: string) => {
      if (roomPax[roomIdx] === undefined) {
        roomPax[roomIdx] = {
          ComponentId: rooms[roomIdx],
          PassengerIds: [],
        };
      }

      roomPax[roomIdx].PassengerIds.push(parseInt(paxId, 10));
    });
  });

  return roomPax;
}

/**
 * Validate the rooming for the BookingState
 *
 * Checks that for the given list of passengers and accommodation, that the selected
 * assignment of passengers to accommodation units is valid to be booked.
 *
 * Performs the following checks in order:
 *
 *  * Ensures rooming is selected
 *  * Ensures that the number of passengers in each unit is within its total occupancy range
 *  * Ensures that the selected passengers are valid to occupy the selected unit
 *  * Ensures that the selected rooms are all available
 *
 * @param {BookingState} state Booking store state
 */
export function validateRooming(state: BookingState): boolean {
  const rooming = getRooming(state);
  // Fail if no rooming is selected
  if (rooming === [] || rooming.length === 0) {
    return false;
  }

  // Check every passenger has a room in each accommodation
  if (flatMap(state.passengerRoomSelection, Object.values).includes(-1)) return false;

  const paxTypes: Map<number, PassengerType> = new Map();

  state.passengers.forEach((pax) => paxTypes.set(pax.id, pax.type));

  // Check that each room is within its occupancy range.
  // and the occupancy is correctly composed
  const validRooms = rooming.map((room) => {
    const unit = state.accommodation.filter((u) => u.Id === room.ComponentId)[0];
    const nPax = room.PassengerIds.length;

    const nAdults = room.PassengerIds.filter((p) => paxTypes.get(p) === 'Adult').length;
    const nChildren = room.PassengerIds.filter((p) => paxTypes.get(p) === 'Child').length;

    // Ensure the total occupancy is within the total occuapncy range
    // and that the adult and child occupancies are also within range.
    return (nPax >= unit.Occupancy.From && nPax <= unit.Occupancy.To)
      && (nAdults >= unit.AdultOccupancy.From && nAdults <= unit.AdultOccupancy.To)
      && (nChildren >= unit.ChildOccupancy.From && nChildren <= unit.ChildOccupancy.To);
  });

  // Ensure no rooms are invalid
  return validRooms.filter((v) => !v).length === 0;
}

// eslint-disable-next-line
function validateRoomCounts(state: BookingState): boolean {
  const totalPax = state.passengers.length;

  if (state.roomCounts === {}) return false;

  const minOccupancy = sum(map(state.roomCounts, (id: string, count: number) => {
    const room = state.accommodation.filter((unit) => unit.Id === id)[0];

    return room.Occupancy.From * count;
  }));

  if (minOccupancy > totalPax) return false;

  const maxOccupancy = sum(map(state.roomCounts, (id: string, count: number) => {
    const room = state.accommodation.filter((unit) => unit.Id === id)[0];

    return room.Occupancy.To * count;
  }));

  if (maxOccupancy < totalPax) return false;

  const available = map(state.roomCounts, (id: string, count: number): boolean => {
    const room = state.accommodation.filter((unit) => unit.Id === id)[0];

    if (room.InventoryDetails.Available < count) return false;

    return true;
  });

  return available.filter((a) => !a).length === 0;
}

export interface GTMProduct {
  name: string;
  id: string;
  price: number;
  quantity?: number;
}

/**
 * Get the booked items as GTM product data
 * @param state
 */
export function gtmProducts(state: BookingState): GTMProduct[] {
  const products: GTMProduct[] = [];

  products.push({
    id: state.departure!.Id,
    name: state.departure!.Name,
    quantity: state.passengers.length,
    price: state.departure!.Pricing.TotalPrice.Value,
  });

  state.accommodation.forEach((u: AccommodationUnit) => {
    if (state.roomCounts[u.Id] > 0) {
      products.push({
        id: u.Id,
        name: u.Name,
        quantity: state.roomCounts[u.Id],
        price: u.Pricing.TotalPrice.Value,
      });
    }
  });

  state.extras.forEach((e: TourExtra) => {
    if (state.selectedExtras.includes(e.Id)) {
      products.push({
        id: e.Id,
        name: e.Name,
        quantity: state.passengerCounts.adults + state.passengerCounts.children,
        price: e.Pricing.TotalPrice.Value,
      });
    }
  });

  return products;
}
