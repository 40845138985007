
















































































import { Inventory } from 'tigerbay/lib/models/tours';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'inventory-table',
})
export default class InventoryTable extends Vue {
  @Prop() inventory!: Inventory;
}
