











































































































































import { Models } from 'tigerbay';
import Vue from 'vue';
import Component from 'vue-class-component';
import API from '@/lib/api';
import TravellerUnit from '@/components/tours/TravellerUnit.vue';
import InventoryTable from '@/components/tours/InventoryTable.vue';

@Component({
  components: {
    TravellerUnit,
    InventoryTable,
  },
})
export default class TourDetails extends Vue {
  tour: Models.Cache.Departure | null = null;

  error: Error | null = null;

  async getTour(id: string): Promise<Models.Cache.Departure> {
    return new API().getTour(id);
  }

  mounted() {
    const { id } = this.$route.params;

    this.getTour(id).then((tour) => {
      this.tour = tour;
      document.title = `Tour ${tour.Name}`;
    }).catch((error) => {
      this.error = error;
    });
  }

  get price(): string {
    if (!this.tour) return '';

    return this.tour.PerPersonGroupTravellerTotalPrice.Value.toLocaleString('en-GB', {
      style: 'currency',
      currency: this.tour.PerPersonGroupTravellerTotalPrice.CurrencyCode,
    });
  }
}
