



























import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState, mapGetters } from 'vuex';
import { Models } from 'tigerbay';
import { sum } from 'lodash';
import PrevStep from '@/components/booking/PrevStep.vue';
import NextStep from '@/components/booking/NextStep.vue';
import Flights from './Flights.vue';

@Component({
  name: 'services-selection',
  components: {
    Flights,
    PrevStep,
    NextStep,
  },
  computed: {
    ...mapState('Bookings', ['extras', 'extraCode']),
    ...mapGetters('Bookings', ['defaultFlights']),
  },
})
export default class ServicesSelection extends Vue {
  extras!: Models.Tours.TourExtra[];

  extraCode!: string;

  defaultFlights!: Models.Tours.Flight[];

  get extraOptions(): Array<{ html: string; value: string; disabled: boolean }> {
    return this.extras.filter((e) => e.Name !== 'Non Member Fee').map((e) => {
      let label = e.Name;
      let isDisabled = false;

      if (!e.IsMandatory && e.Pricing.TotalPrice.Value === 0) {
        label += ' &mdash; Included';
      }

      if (!e.IsMandatory && e.Pricing.TotalPrice.Value > 0) {
        label += ` &mdash; <em>&pound; ${e.Pricing.TotalPrice.Value}</em>`;
      } else {
        isDisabled = true;
      }

      // Cannot de-select primary extra
      if (e.Reference === this.extraCode && e.Reference !== '') {
        isDisabled = true;
      }

      return {
        html: label,
        value: e.Id,
        disabled: isDisabled,
      };
    });
  }

  get selectedExtras(): string[] {
    return this.$store.state.Bookings.selectedExtras;
  }

  set selectedExtras(value: string[]) {
    this.$store.commit('Bookings/setSelectedExtras', value);
  }

  get includeDefaultFlights(): boolean {
    return this.$store.state.Bookings.includeDefaultFlights;
  }

  set includeDefaultFlights(value: boolean) {
    this.$store.commit('Bookings/setIncludeDefaultFlights', value);
  }

  get hasFlights(): boolean {
    return this.defaultFlights.length > 0;
  }

  get totalFlightPrice(): number {
    return sum(
      this.defaultFlights.map(
        (f) => sum(
          f.Flights.map(
            (p) => p.Pricing.TotalPrice.Value,
          ),
        ),
      ),
    );
  }
}
