



















































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';
import ComponentList from '@/components/booking/ComponentList.vue';

interface PaymentOption {
  value: string;
  html: string;
}

@Component({
  name: 'pre-payment',
  components: { ComponentList },
  computed: {
    ...mapState('Bookings', ['totalPrice', 'depositPrice', 'balanceDue']),
  },
})
export default class PrePayment extends Vue {
  readonly balanceDue!: Date

  totalPrice!: number;

  depositPrice!: number;

  paymentOpts: PaymentOption[] = [];

  paymentType = 'deposit'

  get full(): string {
    return this.totalPrice.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
  }

  get deposit(): string {
    return this.depositPrice.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
  }

  get paymentOptions(): PaymentOption[] {
    return this.paymentOpts;
  }

  pay() {
    this.$store.dispatch('Bookings/pay');
  }

  setPaymentType() {
    this.$store.commit('Bookings/setPaymentType', this.paymentType);
  }

  mounted() {
    if (this.deposit && this.depositPrice > 0) {
      this.paymentOpts.push({
        value: 'deposit',
        html: `Deposit Payment: <em>${this.deposit}</em>`,
      });
      this.$store.commit('Bookings/setPaymentType', 'deposit');
    } else {
      this.$store.commit('Bookings/setPaymentType', 'full');
      this.paymentType = 'full';
    }
    if (this.full) {
      this.paymentOpts.push({
        value: 'full',
        html: `Full Payment: <em>${this.full}</em>`,
      });
    }
  }
}
