




















import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { Models } from 'tigerbay';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { Passenger } from '@/store/modules/booking/state';
import PassengerCounts from './PassengerCounts.vue';

@Component({
  mixins: [validationMixin],
  validations: {
    tourCode: {
      required,
    },
  },
  components: { PassengerCounts },
  name: 'availability-check',
  computed: {
    ...mapGetters(['Bookings/initialized', 'Bookings/processing']),
  },
})
export default class AvailabilityCheck extends Vue {
  async initializeBooking() {
    await this.$store.dispatch('Bookings/initialize');
  }

  get tourCode() {
    return this.$store.state.Bookings.tourCode;
  }

  set tourCode(value: string) {
    this.$store.commit('Bookings/setTourCode', value);
  }

  get departureDate() {
    return this.$store.state.Bookings.departureDate;
  }

  set departureDate(value: Date) {
    this.$store.commit('Bookings/setDepartureDate', value);
  }

  get bookingId(): number | undefined {
    return this.$store.state.Bookings.id;
  }

  get passengers(): Array<Passenger> {
    return this.$store.state.Bookings.passengers;
  }

  get tours(): Array<Models.Tours.Tour> {
    return this.$store.state.Bookings.tours;
  }

  selectTour(tour: Models.Tours.Tour) {
    this.$store.commit('Bookings/setTour', tour);
  }
}

