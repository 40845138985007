






































































































































import Vue from 'vue';
import axios from 'axios';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapState } from 'vuex';
import { countries } from 'country-data';
import { sortBy } from 'lodash';
import PrevStep from '@/components/booking/PrevStep.vue';
import { BookingStep, LeadPassenger } from '@/store/modules/booking/state';
import { GTMProduct } from '@/store/modules/booking/getters';

export interface AddressSearchOutput {
  Id: string;
  Type: string;
  Text: string;
  Highlight: string;
  Description: string;
}

@Component({
  name: 'customer-profile',
  components: {
    PrevStep,
  },
  computed: {
    ...mapState('Bookings', ['leadPassenger']),
    ...mapGetters('Bookings', ['gtmProducts']),
  },
  validations: {
    leadPassenger: {
      telephone: { required },
      address: {
        address1: { required },
        city: { required },
        country: { required },
        postcode: { required },
      },
    },
  },
})
export default class CustomerProfile extends Vue {
  leadPassenger!: LeadPassenger

  showLocationList = false

  showAddressList = false

  isSearching = false

  locationList = []

  addressList = []

  postCodeSearched = false

  noResults = false

  terms = 'not_accepted'

  gtmProducts !: GTMProduct[];

  get countries(): Array<{text: string; value: string}> {
    return sortBy(countries.all.filter((c) => c.alpha3 !== '')
      .map((c) => ({ text: c.name, value: c.alpha3 })), 'text');
  }

  book() {
    this.$store.dispatch('Bookings/save');
  }

  async mounted() {
    this.$gtm.trackEvent({
      ecommerce: {
        checkout: { actionField: { step: BookingStep.CUSTOMER_PROFILE } },
        products: this.gtmProducts,
      },
    });
  }

  async addressLookUp() {
    await axios
      .get(
        `https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws?Key=JT22-TU14-EN74-DC94&Text=${this.leadPassenger.address.postcode}&IsMiddleware=True&Countries=${this.leadPassenger.address.country}&Limit=10&Language=&Bias=&Filters=Postcode&GeoFence=`,
      )
      .then((response) => {
        this.locationList = response.data;
        // Display management.
        //
        this.showLocationList = true;
        this.postCodeSearched = true;
        this.noResults = false;
        this.showAddressList = false;
      })
      .catch((err) => {
        this.noResults = true;
        console.log(err);
      });
  }

  async chosenAddress(container: string) {
    await axios
      .get(
        `https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws?Key=JT22-TU14-EN74-DC94&Container=${container}&Text=${this.leadPassenger.address.postcode}&IsMiddleware=True&Countries=${this.leadPassenger.address.country}&Limit=10&Language=&Bias=&Filters=Postcode&GeoFence=`,
      )
      .then((response) => {
        this.addressList = response.data;
        // Display management.
        //
        this.showAddressList = true;
        this.postCodeSearched = true;
        this.noResults = false;
        this.showLocationList = false;
      })
      .catch((err) => {
        this.noResults = true;
        console.log(err);
      });
  }

  async emitAddress(data: AddressSearchOutput) {
    await axios
      .get(
        `https://api.addressy.com/Capture/Interactive/Retrieve/v1.20/json3.ws?Key=JT22-TU14-EN74-DC94&Id=${data.Id}`,
      )
      .then((response) => {
        const address = response.data.Items[0];
        // Update address data.
        //
        this.leadPassenger.address.city = address.City;
        this.leadPassenger.address.address1 = address.Line1;
        this.leadPassenger.address.address2 = address.Line2;
        // Display management.
        //
        this.showAddressList = false;
        this.postCodeSearched = false;
        this.noResults = false;
        this.showLocationList = false;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  emitLocation(data: AddressSearchOutput) {
    this.chosenAddress(data.Id);
  }

  @Watch('leadPassenger', { deep: true })
  onLeadPassengerChange(value: LeadPassenger) {
    this.$store.commit('Bookings/setLeadPassenger', value);
  }
}
