import { Price } from 'tigerbay/lib/models/reservations';
import Vue from 'vue';

Vue.filter('price', (price: Price): string => {
  if (price === null || price === undefined) return '';

  return price.Value.toLocaleString('en-GB', {
    style: 'currency',
    currency: price.CurrencyCode,
    minimumFractionDigits: 2,
  });
});

Vue.filter('currency', (code: string) => {
  const currencySymbols: Record<string, string> = {
    USD: '$',
    EUR: '€',
    GBP: '£',
  };

  return currencySymbols[code];
});

Vue.filter('priceCalculated', (price: number, currencyCode: string) => price.toLocaleString('en-GB', {
  style: 'currency',
  currency: currencyCode,
  minimumFractionDigits: 2,
}));
