
























































import Vue from 'vue';
import Component from 'vue-class-component';

import { mapGetters } from 'vuex';

@Component({
  computed: {
    ...mapGetters('Bookings', ['step']),
  },
})
export default class extends Vue {
  get booking() {
    return this.$store.state.Bookings;
  }
}
