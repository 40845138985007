import Summary from '@/components/account/Summary.vue';
import BookingComponent from '@/components/account/BookedItem.vue';
import Customer from '@/components/account/Customer.vue';
import Passenger from '@/components/account/Passenger.vue';
import Note from '@/components/account/Note.vue';
import NewNote from '@/components/account/NewNote.vue';
import NewPayment from '@/components/account/NewPayment.vue';

export default {
  Summary,
  BookingComponent,
  Customer,
  Passenger,
  Note,
  NewNote,
  NewPayment,
};
