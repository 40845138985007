import { Models } from 'tigerbay';
import { Gender } from 'tigerbay/lib/models/customers';

export enum BookingStep {
  AVAILABILITY_SEARCH,
  PASSENGER_DETAILS,
  ROOM_SELECTION,
  ROOM_ALLOCATION,
  SERVICES_SELECTION,
  CUSTOMER_PROFILE,
  PREPAYMENT,
}

export enum BookingStatus {
  INPROGRESS,
  CONFIRMED,
  FAILED_PAYMENT,
  FAILED_AVAILABILITY,
  FAILED_OTHER,
}

export type RoomSelections = Record<string, Record<number, number>>;

export type PaymentType = 'full' | 'deposit';

export interface PassengerCounts {
  adults: number;
  children: number;
}
export interface Passenger {
  id: number;
  title: string;
  forename: string;
  surname: string;
  type: Models.Reservations.PassengerType;
  dateOfBirth: number;
  specialRequests?: string;
  isMember: boolean;
  gender: Gender;
}

export interface LeadPassenger {
  email: string;
  telephone: string;
  address: Address;
  gender: Gender;
}

export interface Address {
  country: string;
  address1: string;
  address2: string;
  city: string;
  postcode: string;
}

export default class BookingState {
  public loading: boolean;

  public token: string;

  public id: number;

  public step: BookingStep;

  public expired: boolean;

  public processing: boolean;

  public searchId: string;

  public tourCode: string;

  public balanceDue: Date | null;

  public extraCode = '';

  public departureDate: Date | null;

  public passengers: Array<Passenger>;

  public accommodation: Array<Models.Tours.AccommodationUnit>;

  public extras: Models.Tours.TourExtra[];

  public flights: Models.Tours.Flight[];

  public departure: Models.Tours.Departure | null;

  public passengerCounts: PassengerCounts;

  public error?: Error;

  public roomCounts: Record<string, number>;

  public passengerRoomSelection: RoomSelections;

  public includeDefaultFlights: boolean;

  public selectedExtras: string[];

  public leadPassenger: LeadPassenger;

  public totalPrice: number;

  public depositPrice: number;

  public contactId: number;

  public paymentType: PaymentType;

  public paymentUrl: string;

  public paymentId: number;

  public status: BookingStatus = BookingStatus.INPROGRESS;

  public bookingReference = '';

  public expiresHandle: number | null = null;

  public expiresAt: number | null = null;

  constructor() {
    this.loading = false;
    this.token = '';
    this.id = 0;
    this.processing = false;
    this.step = BookingStep.AVAILABILITY_SEARCH;
    this.passengerCounts = {
      adults: 1,
      children: 0,
    };
    this.searchId = '';
    this.tourCode = '';
    this.balanceDue = null;
    this.departureDate = null;
    this.passengers = [];
    this.accommodation = [];
    this.extras = [];
    this.flights = [];
    this.roomCounts = {};
    this.passengerRoomSelection = {};
    this.includeDefaultFlights = true;
    this.selectedExtras = [];
    this.leadPassenger = {
      email: '',
      telephone: '',
      address: {
        country: 'GBR',
        postcode: '',
        address1: '',
        address2: '',
        city: '',
      },
      gender: 'NotSet',
    };
    this.totalPrice = NaN;
    this.depositPrice = NaN;
    this.contactId = NaN;
    this.paymentType = 'full';
    this.paymentUrl = '';
    this.paymentId = NaN;
    this.departure = null;
    this.expired = false;
    this.extraCode = '';
  }
}
