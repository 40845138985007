



























































































































































































































































import { Models } from 'tigerbay';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'tour-search-result-card',
})
export default class TourSearchResultCard extends Vue {
  @Prop() readonly tour!: Models.Cache.Departure;

  get startDate(): string {
    if (typeof (this.tour.StartDate) === 'string') {
      return this.tour.StartDate.split('T')[0];
    }

    return this.tour.StartDate.toISOString().split('T')[0];
  }
}
