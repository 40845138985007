










































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { AccommodationUnit } from 'tigerbay/lib/models/tours';
import { PassengerAssignment } from 'tigerbay/lib/models/common';
import { Passenger } from '@/store/modules/booking/state';

@Component({
  name: 'passenger-room-selection',
  computed: {
    ...mapState('Bookings', ['passengerRoomSelection']),
    ...mapGetters('Bookings', ['rooms', 'rooming']),
  },
})
export default class PassengerRoomSelection extends Vue {
  @Prop() passenger!: Passenger

  @Prop() accommodation!: number

  @Prop() options!: AccommodationUnit[]

  rooms!: string[]

  passengerRoomSelection!: Record<number, Record<number, number>>;

  rooming!: PassengerAssignment[];

  get room(): number {
    return this.passengerRoomSelection[this.accommodation][this.passenger.id];
  }

  set room(value: number) {
    const r = this.passengerRoomSelection;
    r[this.accommodation][this.passenger.id] = value;

    this.$store.commit('Bookings/setRoomSelections', r);
  }

  get fullName(): string {
    return `${this.passenger.forename} ${this.passenger.surname}`;
  }

  get roomOptions(): Array<{ text: string; value: number }> {
    const options: Array<{ text: string; value: number }> = [];

    this.rooms.forEach((r, idx) => {
      const room = this.options.filter((u) => u.Id === r)[0];

      if (room === undefined) return;

      const label = 'Room ';
      const rmNum = idx + 1;

      options.push({ text: label + rmNum, value: idx });
    });

    return options;
  }

  get valid(): boolean {
    return !this.isUnderoccupied && !this.isOveroccupied;
  }

  get isUnderoccupied(): boolean {
    if (this.room < 0) return false;

    const room = this.roomInfo;

    return this.rooming[this.room].PassengerIds.length < room.Occupancy.From;
  }

  get isOveroccupied(): boolean {
    if (this.room < 0) return false;

    const room = this.roomInfo;

    return this.rooming[this.room].PassengerIds.length > room.Occupancy.To;
  }

  private get roomInfo(): AccommodationUnit {
    const roomId = this.rooms[this.room];
    const room = this.options.filter((r) => r.Id === roomId)[0];

    return room;
  }
}
