var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zen-vue-app__steps"},[_c('ol',{staticClass:"zen-list zen-list--no-type zen-list--booking-steps"},[_c('li',{class:{
          'complete': _vm.step > 1,
          'active': _vm.step === 1
        }},[_vm._m(0)]),_c('li',{class:{
					'complete': _vm.step > 2,
          'active': _vm.step === 2
        }},[_vm._m(1)]),_c('li',{class:{
					'complete': _vm.step > 3,
          'active': _vm.step === 3
        }},[_vm._m(2)]),_c('li',{class:{
					'complete': _vm.step > 4,
          'active': _vm.step === 4
        }},[_vm._m(3)]),_c('li',{class:{
          'active': _vm.step === 5
        }},[_vm._m(4)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zen-tag zen-tag--step"},[_c('span',{staticClass:"zen-text"},[_vm._v("Step 1")]),_c('i',{staticClass:"zen-icon feather icon-check-circle"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zen-tag zen-tag--step"},[_c('span',{staticClass:"zen-text"},[_vm._v("Step 2")]),_c('i',{staticClass:"zen-icon feather icon-check-circle"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zen-tag zen-tag--step"},[_c('span',{staticClass:"zen-text"},[_vm._v("Step 3")]),_c('i',{staticClass:"zen-icon feather icon-check-circle"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zen-tag zen-tag--step"},[_c('span',{staticClass:"zen-text"},[_vm._v("Step 4")]),_c('i',{staticClass:"zen-icon feather icon-check-circle"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zen-tag zen-tag--step"},[_c('span',{staticClass:"zen-text"},[_vm._v("Step 5")]),_c('i',{staticClass:"zen-icon feather icon-check-circle"})])}]

export { render, staticRenderFns }