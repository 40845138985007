
















































































import Vue from 'vue';
import { Flight } from 'tigerbay/lib/models/tours';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component({})
export default class Flights extends Vue {
  @Prop() readonly flights!: Flight[]
}
