





















































import { AccommodationUnit } from 'tigerbay/lib/models/tours';
import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

import InventoryTable from './InventoryTable.vue';

@Component({
  name: 'traveller-unit',
  components: {
    InventoryTable,
  },
})
export default class TravellerUnit extends Vue {
  @Prop() unit!: AccommodationUnit;
}
