






























import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class extends Vue {
  get adults() {
    return this.$store.state.Bookings.passengerCounts.adults;
  }

  set adults(value) {
    this.$store.commit('Bookings/setAdults', value);
  }

  get children() {
    return this.$store.state.Bookings.passengerCounts.children;
  }

  set children(value) {
    this.$store.commit('Bookings/setChildren', value);
  }

  mounted() {
    if (typeof this.$route.query.adults === 'string') {
      this.adults = parseInt(this.$route.query.adults, 10);
    }

    if (typeof this.$route.query.children === 'string') {
      this.children = parseInt(this.$route.query.children, 10);
    }
  }
}
