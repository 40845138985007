




































































































import Component from 'vue-class-component';
import Vue from 'vue';
import {
  required, between, minValue, maxLength, maxValue,
} from 'vuelidate/lib/validators';
import { Model, Watch, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';

export interface Pax {
  id: string;
  forename: string;
  surname: string;
  type: string;
  title: string;
  dateOfBirth: string;
  gender: string;
  isMember: boolean;
}

Component.registerHooks(['validations']);
@Component({
  name: 'date-of-birth',
  computed: {
    ...mapState('Bookings', ['step', 'passengers']),

    days() {
      return Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, '0'));
    },
    years() {
      const year = new Date().getFullYear();
      return Array.from({ length: year - 1900 }, (value, index) => 1901 + index).reverse();
    },
  },
})
export default class LeadDateOfBirth extends Vue {
  @Prop() readonly index!: number

  readonly passengers!: Array<Pax>

  readonly step!: number

  bDay = '';

  bMonth = '';

  bYear = '';

  dob: Array<{day: string; month: string; year: string}> = [];

  monthOptions = [
    { value: '', text: 'Month' },
    { value: '01', text: 'Jan' },
    { value: '02', text: 'Feb' },
    { value: '03', text: 'Mar' },
    { value: '04', text: 'Apr' },
    { value: '05', text: 'May' },
    { value: '06', text: 'Jun' },
    { value: '07', text: 'Jul' },
    { value: '08', text: 'Aug' },
    { value: '09', text: 'Sep' },
    { value: '10', text: 'Oct' },
    { value: '11', text: 'Nov' },
    { value: '12', text: 'Dec' },
  ]

  minimumAge() {
    const minAge = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
    minAge.setHours(0, 0, 0, 0);
    return minAge.getFullYear();
  }

  // Inline component validations.
  //
  validations() {
    return {
      bDay: {
        required,
        between: between(1, 31),
      },
      bMonth: {
        required,
        between: between(1, 12),
      },
      bYear: {
        required,
        minValue: minValue(1900),
        maxValue: maxValue(this.minimumAge()),
        maxLength: maxLength(4),
      },
    };
  }

  @Model('change', { type: Number }) readonly date!: number;

  setDob() {
    this.dob = [];
    const setData = this.dob;
    // Push keyup data.
    //
    this.$nextTick(() => {
      if (this.bDay.length > 0 || this.bMonth.length > 0 || this.bYear.length >= 4) {
        setData.push({
          day: this.bDay,
          month: this.bMonth,
          year: this.bYear,
        });

        const date = this.getDate();

        if (date) {
          const yearCheck = !this.$v.bYear.$invalid;
          this.$emit('change', date);
          this.$emit('eventValidate', yearCheck);
        }
      }
    });
  }

  mounted() {
    let date;
    const yearCheck = !this.$v.bYear.$invalid;
    if (this.passengers[this.index].dateOfBirth) {
      date = new Date(this.passengers[this.index].dateOfBirth);
      this.bDay = date.getUTCDate().toString().padStart(2, '0');
      this.bMonth = (date.getUTCMonth() + 1).toString().padStart(2, '0');
      this.bYear = date.getUTCFullYear().toString();
    } else if (this.date) {
      date = new Date(this.date);
      this.bDay = date.getUTCDate().toString().padStart(2, '0');
      this.bMonth = (date.getUTCMonth() + 1).toString().padStart(2, '0');
      this.bYear = date.getUTCFullYear().toString();
    }
    this.setDob();
    this.$emit('eventValidate', yearCheck);
  }

  getDate(): number {
    const day = parseInt(this.bDay, 10);
    const month = parseInt(this.bMonth, 10);
    const year = parseInt(this.bYear, 10);

    if (day && month && year) {
      return Date.UTC(year, month - 1, day);
    }

    return NaN;
  }

  @Watch('step')
  onStepBack() {
    if (this.step === 2) {
      this.getDate();
    }
  }
}
