








































































import Component, { mixins } from 'vue-class-component';

import { mapGetters } from 'vuex';
import { CacheSearchRequest } from 'tigerbay/lib/models/cache';
import TourSearchForm from '@/components/tours/TourSearchForm.vue';
import TourSearchResultCard from '@/components/tours/TourSearchResultCard.vue';
import { TourSortProperty } from '@/store/modules/tours';
import API from '@/lib/api';
import ErrorHandling from '@/mixins/errorHandling';

@Component({
  components: {
    TourSearchForm,
    TourSearchResultCard,
  },

  computed: {
    ...mapGetters('Tours', ['tours', 'count']),
  },
})
export default class TourSearch extends mixins(ErrorHandling) {
  sort: TourSortProperty = 'StartDate';

  async search(query: CacheSearchRequest) {
    const api = new API();
    const tours = await api.searchTours(query);
    this.$store.commit('Tours/setTours', tours);
  }

  sortOptions: Array<{text: string; value: TourSortProperty}> = [
    { text: 'Code', value: 'Code' },
    { text: 'Duration', value: 'Duration' },
    { text: 'End Date', value: 'EndDate' },
    { text: 'Generation Date', value: 'Generated' },
    { text: 'ID', value: 'Id' },
    { text: 'Name', value: 'Name' },
    { text: 'Start Date', value: 'StartDate' },
  ];
}
