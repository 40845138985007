import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Update extends Vue {
  registration: ServiceWorkerRegistration | null = null

  updateExists = false

  refreshing = false

  created() {
    // Pass swUpdatedEvents to updateAvailable() if the SW is updated.
    //
    document.addEventListener('swUpdated', this.updateAvailable, { once: true });
    // ToDo: Unknown use. Currently getting errors in the console.
    //
    /* navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
    }); */
  }

  updateAvailable(event: CustomEvent<ServiceWorkerRegistration>): void {
    this.registration = event.detail;
    this.updateExists = true;
    this.installUpdate();
  }

  installUpdate() {
    this.updateExists = false;
    if (!this.registration || !this.registration.waiting) return;
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  }
}
