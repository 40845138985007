import { Models } from 'tigerbay';

export default class AccountState {
  public token = '';

  public booking: Models.Reservations.Reservation | null = null;

  public customer: Models.Customers.Customer | null = null;

  public components: Models.Reservations.BookingComponentListEntry[] = [];

  public passengers: Models.Reservations.Passenger[] = [];

  public notes: Models.Notes.Note[] = [];

  public payments: Models.Payments.Payment[] = [];

  public currentPaymentId: number | null = null;

  public lastUpdated: number | null = null;
}
