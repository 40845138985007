import Vue from 'vue';
import Component from 'vue-class-component';
import { AxiosError } from 'axios';
import Sentry from '@sentry/vue';

export interface BackendError {
  code: string;
  message: string;
  traceId: string | null;
}

@Component
export default class ErrorHandling extends Vue {
  backendError: BackendError | null = null

  created(): void {
    document.addEventListener('backendError', this.handleBackendError);
  }

  handleBackendError(event: CustomEvent<AxiosError>): void {
    const error = event.detail;

    this.$store.commit('Bookings/setProcessing', false);

    if (error.response && error.response.data) {
      const { code, message } = error.response.data;
      const traceId = error.response.headers['x-trace-id'];
      this.backendError = {
        code,
        message,
        traceId,
      };
    } else {
      this.backendError = {
        code: 'E_EXTRA_AVAIL',
        message: error.message,
        traceId: null,
      };
    }
  }
}
