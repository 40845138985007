































































import Vue from 'vue';
import { mapState } from 'vuex';
import Component from 'vue-class-component';
import { BookingComponentListEntry } from 'tigerbay/lib/models/reservations';

@Component({
  computed: {
    ...mapState('Bookings', ['loading']),
  },
})
export default class ComponentList extends Vue {
  components: BookingComponentListEntry[] = [];

  readonly loading!: boolean

  async mounted() {
    this.$store.commit('Bookings/setLoadingState', true);
    this.components = await this.$store.dispatch('Bookings/listComponents');
  }
}
