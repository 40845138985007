










import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { mapMutations } from 'vuex';

@Component({
  name: 'next-step',

  methods: {
    ...mapMutations('Bookings', ['nextStep']),
  },
})
export default class NextStep extends Vue {
  @Prop() valid!: boolean;
}
