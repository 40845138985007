



















































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { BIconCheckCircle, BIconExclamationCircleFill } from 'bootstrap-vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { AccommodationUnit } from 'tigerbay/lib/models/tours';
import { Watch } from 'vue-property-decorator';
import { sum } from 'lodash';
import { Passenger } from '@/store/modules/booking/state';
import NextStep from '@/components/booking/NextStep.vue';
import InputStepper from '@/components/elements/InputStepper.vue';

@Component({
  components: {
    InputStepper,
    NextStep,
    BIconCheckCircle,
    BIconExclamationCircleFill,
  },
  name: 'room-selection',

  computed: {
    ...mapState('Bookings', ['passengers', 'accommodation', 'roomCounts']),
    ...mapGetters('Bookings', ['accommodationRooms']),
  },

  methods: {
    ...mapMutations('Bookings', ['nextStep']),
  },
})
export default class RoomSelection extends Vue {
  roomCounts!: Record<string, number>;

  passengers!: Passenger[];

  accommodationRooms!: Record<string, AccommodationUnit[]>;

  get roomCount() {
    return this.$store.getters['Bookings/rooms'].length;
  }

  get totalPax(): number {
    return this.passengers.length;
  }

  get valid(): boolean {
    const valids = Object.keys(this.accommodationRooms).map(this.accommodationValid);

    return valids.filter((v) => !v).length === 0;
  }

  setStep(step: number) {
    this.$store.commit('Bookings/setStep', step);
  }

  minimumOccupancy(accom: string): number {
    const counts = this.accommodationRooms[accom].map(
      (room) => room.Occupancy.From * (this.roomCounts[room.Id] || 0),
    );

    return sum(counts);
  }

  maximumOccupancy(accom: string): number {
    const counts = this.accommodationRooms[accom].map(
      (room) => room.Occupancy.To * (this.roomCounts[room.Id] || 0),
    );

    return sum(counts);
  }

  accommodationValid(accom: string): boolean {
    return this.minimumOccupancy(accom) <= this.totalPax
      && this.maximumOccupancy(accom) >= this.totalPax;
  }

  @Watch('roomCounts', { deep: true })
  updateRooms(val: Record<string, number>) {
    this.$store.commit('Bookings/setRoomCounts', val);
  }
}
