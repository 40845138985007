import { Models } from 'tigerbay';
import { Customer } from 'tigerbay/lib/models/customers';
import { Note } from 'tigerbay/lib/models/notes';
import { Payment } from 'tigerbay/lib/models/payments';
import { Reservation } from 'tigerbay/lib/models/reservations';
import { MutationTree } from 'vuex';
import AccountState from './state';

const mutations: MutationTree<AccountState> = {
  setBooking(state: AccountState, booking: Reservation) {
    state.lastUpdated = new Date().getTime();
    state.booking = booking;
  },

  setCustomer(state: AccountState, customer: Customer) {
    state.customer = customer;
  },

  setToken(state: AccountState, token: string) {
    state.token = token;
  },

  setComponents(state: AccountState, components: Models.Reservations.BookingComponentListEntry[]) {
    state.components = components;
  },

  setPassengers(state: AccountState, passengers: Models.Reservations.Passenger[]) {
    state.passengers = passengers;
  },

  setNotes(state: AccountState, notes: Note[]) {
    state.notes = notes;
  },

  addNote(state: AccountState, note: Note) {
    state.notes.push(note);
  },

  setPayments(state: AccountState, payments: Payment[]) {
    state.payments = payments;
  },

  setCurrentPaymentId(state: AccountState, id: number | null) {
    state.currentPaymentId = id;
  },

  logout(state: AccountState) {
    state.token = '';
    state.currentPaymentId = null;
    state.passengers = [];
    state.booking = null;
    state.components = [];
    state.notes = [];
    state.customer = null;
    state.payments = [];
    state.lastUpdated = null;
  },
};

export default mutations;
