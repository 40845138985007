import { MutationTree } from 'vuex';
import { Models } from 'tigerbay';
import { PassengerAssignment } from 'tigerbay/lib/models/common';
import { AccommodationUnit } from 'tigerbay/lib/models/tours';
import { uniq, groupBy } from 'lodash';
import actions from './booking/actions';
import BookingState, {
  BookingStatus,
  BookingStep,
  LeadPassenger,
  Passenger,
  PaymentType,
  RoomSelections,
} from './booking/state';
import {
  getRooming, getRooms, validateRooming, gtmProducts,
} from './booking/getters';

const state = new BookingState();

const mutations: MutationTree<BookingState> = {
  setLoadingState: (state: BookingState, loading: boolean) => {
    state.loading = loading;
  },

  setId: (state: BookingState, id: number) => {
    state.id = id;
  },

  setProcessing: (state: BookingState, processing: boolean) => {
    state.processing = processing;
  },

  setSearchId: (state: BookingState, id: string) => {
    state.searchId = id;
  },

  setTourCode: (state: BookingState, tourCode: string) => {
    state.tourCode = tourCode;
  },

  setExtraCode(state: BookingState, extraCode: string) {
    state.extraCode = extraCode;
  },

  setBalanceDue: (state: BookingState, date: Date) => {
    state.balanceDue = date;
  },

  setDepartureDate: (state: BookingState, date: Date) => {
    state.departureDate = date;
  },

  setPassengers: (state: BookingState, passengers: Array<Passenger>) => {
    state.passengers = passengers;
  },

  passengers: (state: BookingState, passengers: Array<Passenger>) => {
    state.passengers = passengers;
  },

  setDeparture: (state: BookingState, tour: Models.Tours.Departure) => {
    state.departure = tour;
  },

  setAccommodation: (state: BookingState, accommodation: Array<Models.Tours.AccommodationUnit>) => {
    state.accommodation = accommodation;
  },

  setExtras: (state: BookingState, extras: Models.Tours.TourExtra[]) => {
    state.extras = extras;
  },

  setSelectedExtras: (state: BookingState, extraIds: string[]) => {
    state.selectedExtras = extraIds;
  },

  setFlights: (state: BookingState, flights: Models.Tours.Flight[]) => {
    state.flights = flights;
  },

  setIncludeDefaultFlights: (state: BookingState, include: boolean) => {
    state.includeDefaultFlights = include;
  },

  setTotalPrice: (state: BookingState, value: number) => {
    state.totalPrice = value;
  },

  setDepositPrice: (state: BookingState, value: number) => {
    state.depositPrice = value;
  },

  setContactId: (state: BookingState, id: number) => {
    state.contactId = id;
  },

  setPaymentType: (state: BookingState, type: PaymentType) => {
    state.paymentType = type;
  },

  setPaymentUrl: (state: BookingState, url: string) => {
    state.paymentUrl = url;
  },

  setPaymentId: (state: BookingState, id: number) => {
    state.paymentId = id;
  },

  setAdults: (state: BookingState, adults: number) => {
    state.passengerCounts.adults = adults;
  },

  setChildren: (state: BookingState, children: number) => {
    state.passengerCounts.children = children;
  },

  setStep: (state: BookingState, step: BookingStep) => {
    state.step = step;
  },

  prevStep: (state: BookingState) => {
    state.step -= 1;

    /**
     * Skip the SERVICES_SELECTION step unless there are flights...
     */
    if (state.step === BookingStep.SERVICES_SELECTION && state.flights.length === 0) {
      state.step -= 1;
    }
  },

  nextStep: (state: BookingState) => {
    state.step += 1;

    /**
     * Skip the SERVICES_SELECTION step unless there are flights...
     */
    if (state.step === BookingStep.SERVICES_SELECTION && state.flights.length === 0) {
      state.step += 1;
    }
  },

  setRoomCounts: (state: BookingState, roomCounts: Record<string, number>) => {
    state.roomCounts = roomCounts;

    const stays = uniq(state.accommodation.map((a) => (
      `${new Date(a.Duration.From).getTime()}-${a.Accommodation.SetupId}`
    )));

    const passengerRoomSelections: RoomSelections = {};

    // Generate passenger room assignment data
    // with passengers being unassigned from any room
    stays.forEach((a) => {
      const selections: Record<number, number> = {};

      state.passengers.forEach((p) => {
        selections[p.id] = -1;
      });

      passengerRoomSelections[a] = selections;
    });

    // Reset room selections
    state.passengerRoomSelection = passengerRoomSelections;
  },

  setRoomSelections: (state: BookingState, selections: RoomSelections) => {
    state.passengerRoomSelection = selections;
  },

  setLeadPassenger: (state: BookingState, payload: LeadPassenger) => {
    state.leadPassenger = payload;
  },

  setExpired(state: BookingState, expired: boolean) {
    state.expired = expired;
  },

  setError: (state: BookingState, error?: Error) => {
    state.error = error;
  },

  setToken: (state: BookingState, token: string) => {
    state.token = token;
  },

  setStatus: (state: BookingState, status: BookingStatus) => {
    state.status = status;
  },

  setReference(state: BookingState, reference: string) {
    state.bookingReference = reference;
  },

  setExpiresHandle(state: BookingState, handle: number | null) {
    state.expiresHandle = handle;
  },

  cancelExpiration(state: BookingState) {
    if (state.expiresHandle !== null) {
      clearTimeout(state.expiresHandle);
      state.expiresHandle = null;
    }
  },

  setExpires(state: BookingState, at: number | null) {
    state.expiresAt = at;
  },

  /**
   * Reset the booking state
   */
  reset: (state: BookingState) => {
    state.processing = false;
    state.expiresAt = null;
    state.expired = false;
    state.expiresHandle = null;
    state.status = BookingStatus.INPROGRESS;
    state.bookingReference = '';
    state.step = BookingStep.AVAILABILITY_SEARCH;
    state.id = 0;
    state.departure = null;
    state.passengerCounts = {
      adults: 1,
      children: 0,
    };
    state.searchId = '';
    state.tourCode = '';
    state.balanceDue = new Date();
    state.departureDate = new Date();
    state.passengers = [];
    state.accommodation = [];
    state.extras = [];
    state.flights = [];
    state.roomCounts = {};
    state.passengerRoomSelection = {};
    state.includeDefaultFlights = true;
    state.selectedExtras = [];
    state.leadPassenger = {
      email: '',
      telephone: '',
      address: {
        country: 'GBR',
        postcode: '',
        address1: '',
        address2: '',
        city: '',
      },
      gender: 'NotSet',
    };
    state.totalPrice = NaN;
    state.depositPrice = NaN;
    state.contactId = NaN;
    state.paymentType = 'full';
    state.paymentUrl = '';
    state.paymentId = NaN;
    state.expired = false;
  },
};

export type AccommodationSort = 'Duration';

const getters = {
  initialized: (state: BookingState) => state.id !== 0,
  id: (state: BookingState) => state.id,
  departure: (state: BookingState) => state.departure,
  step: (state: BookingState) => state.step,
  processing: (state: BookingState) => state.processing,
  passengers: (state: BookingState) => state.passengers,
  error: (state: BookingState) => state.error,
  rooming: (state: BookingState): Array<PassengerAssignment> => getRooming(state),
  rooms: (state: BookingState): string[] => getRooms(state),
  accommodationRooms: (state: BookingState): Record<string, AccommodationUnit[]> => groupBy(
    state.accommodation, (u) => (
      `${new Date(u.Duration.From).getTime()}-${u.Accommodation.SetupId}`
    ),
  ),
  roomingValid: (state: BookingState): boolean => validateRooming(state),
  defaultFlights: (state: BookingState): Models.Tours.Flight[] => state.flights.filter(
    (f) => f.RateOption === 'Default',
  ),
  status: (state: BookingState): BookingStatus => state.status,
  reference: (state: BookingState): string => state.bookingReference,

  expiresHandle: (state: BookingState): number | null => state.expiresHandle,

  expired(state: BookingState): boolean {
    return state.expired || (state.expiresAt !== null && state.expiresAt < Date.now());
  },

  /**
   * Get the time until expiry (in milliseconds)
   * @param state
   * @return number | null Expiry
   */
  expiresIn(state: BookingState): number | null {
    if (state.expired) return 0;
    if (state.expiresAt === null) return null;
    return state.expiresAt - Date.now();
  },
  gtmProducts,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
