import AccountState from './account/state';
import actions from './account/actions';
import mutations from './account/mutations';
import getters from './account/getters';

const state = new AccountState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
