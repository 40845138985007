

















import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class BookingFailure extends Vue {
  ref = this.$route.params.id
}
