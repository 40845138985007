












































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

@Component({
  computed: {
    ...mapState('Bookings', [
      'departure',
      'step',
      'passengerCounts',
      'accommodation',
      'departureDate',
      'totalPrice',
    ]),
  },
})
export default class extends Vue {
  get booking() {
    return this.$store.state.Bookings;
  }
}
