import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import Bookings from './modules/booking';
import Tours from './modules/tours';
import Account from './modules/account';

Vue.use(Vuex);

const persistence = new VuexPersistence({
  storage: window.localStorage,
  key: 'xhf-appstate',
  modules: ['Bookings', 'Account'],
});

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Bookings,
    Tours,
    Account,
  },
  plugins: [persistence.plugin],
});

export default store;
