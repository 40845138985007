





















































































import { Reservation } from 'tigerbay/lib/models/reservations';
import Component, { mixins } from 'vue-class-component';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import ErrorHandling from '@/mixins/errorHandling';

@Component({
  validations: {
    response: { maxLength: maxLength(1) },
    bookingReference: { required },
    email: { required, email },
    name: { required },
  },

  computed: {
    ...mapGetters('Account', ['isTokenSet', 'booking']),
  },
})
export default class Login extends mixins(ErrorHandling) {
  loading = false;

  response = '';

  bookingReference = '';

  booking!: Reservation;

  email = '';

  name = '';

  isTokenSet!: boolean;

  async login() {
    this.response = '';
    this.loading = true;
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.loading = false;
    } else {
      try {
        const loggedIn = await this.$store.dispatch('Account/login', {
          email: this.email,
          bookingReference: this.bookingReference,
          name: this.name,
        });

        if (loggedIn) {
          await this.$router.push(`/my-booking/${this.bookingReference}`);
        }
        this.loading = false;
      } catch (e) {
        this.response = 'Login Failed';
        this.loading = false;
      }
    }
  }

  clearResponse() {
    this.response = '';
  }

  mounted() {
    if (this.isTokenSet) {
      this.$router.push(`/my-booking/${this.booking.BookingReference}`);
    }
    document.title = 'Manage your HF Booking';
  }
}

