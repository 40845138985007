import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import {
  LayoutPlugin, CardPlugin, TablePlugin, FormPlugin, FormTagsPlugin,
  FormGroupPlugin, FormInputPlugin, FormRadioPlugin, FormSelectPlugin,
  FormDatepickerPlugin, FormTextareaPlugin, FormCheckboxPlugin,
  FormSpinbuttonPlugin, BadgePlugin, AlertPlugin, SpinnerPlugin, TabsPlugin,
  CollapsePlugin,
} from 'bootstrap-vue';
import VueFilterDateFormat from 'vue-filter-date-format';
import Vuelidate from 'vuelidate';

import './registerComponentHooks';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

/**
* Stylesheets.
*/
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/scss/template.scss';
import '@/lib/filters/global';
import '@/lib/filters/price';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    release: `hf-bookings@${process.env.VUE_APP_VERSION || 'head'}`,
    dsn: 'https://f5c2a3be5e1a47cf9c98174bd3c618e7@o73279.ingest.sentry.io/5658140',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    tracingOptions: {
      trackComponents: true,
    },
    logErrors: true,
    environment: process.env.VUE_APP_ENV || 'development',
  });
}

Vue.config.productionTip = false;
Vue.use(VueGtm, {
  id: 'GTM-N94BJGJ',
  defer: false,
  compatibility: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});
Vue.use(VueFilterDateFormat);
Vue.use(Vuelidate);

Vue.use(LayoutPlugin);
Vue.use(CardPlugin);
Vue.use(TablePlugin);
Vue.use(FormPlugin);
Vue.use(FormTagsPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormSpinbuttonPlugin);
Vue.use(BadgePlugin);
Vue.use(AlertPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TabsPlugin);
Vue.use(CollapsePlugin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
// // Load Bootstrap Asynchronously as a separate chunk
// // This reduces the vendor payload size and
// // means bootstrap can be separately cached by clients
// import(/* webpackChunkName: "bootstrap" */ 'bootstrap-vue').then((mod) => {
//   Vue.use(mod.BootstrapVue);
//   Vue.use(mod.IconsPlugin);

//   new Vue({
//     router,
//     store,
//     render: (h) => h(App),
//   }).$mount('#app');
// });
