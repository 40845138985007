import { CreateCustomerRequest, CustomerContact } from 'tigerbay/lib/models/customers';
import {
  AddPassengerRequest,
  BookingComponentListEntry, ReservationUpdateOperation,
  ServiceAssignmentRequest,
} from 'tigerbay/lib/models/reservations';
import { orderBy } from 'lodash';
import API from '@/lib/api';
import { getRooming } from './getters';
import BookingState, {
  BookingStatus, BookingStep, LeadPassenger, Passenger,
} from './state';

const api = new API();

enum CustomerType {
  NotMember = 1,
  Member = 4,
}

async function initialize({ commit, state }: { commit: Function; state: BookingState }) {
  if (state.passengerCounts.adults === 0) throw Error('Must have at least 1 adult');
  if (state.tourCode === '') throw Error('Tour code must be specified');
  if (state.departureDate === undefined) throw Error('Departure date must be specified');

  commit('setProcessing', true);

  let bookingId: number;

  if (state.id === 0) {
    const { id, token, expiresIn } = await api.initializeBooking();
    commit('setId', id);
    commit('setToken', token);

    commit('setExpires', Date.now() + (expiresIn * 1000));
    commit('setExpiresHandle', setTimeout(() => {
      commit('setExpired', true);
    }, expiresIn * 1000));

    bookingId = id;
    api.token = token;
  } else {
    bookingId = state.id;
    api.token = state.token;
  }

  const placeholders: Array<AddPassengerRequest> = [];

  const adultDob = new Date(Date.UTC(
    new Date().getUTCFullYear() - 20,
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    0, 0, 0,
  ));

  // Set the passengers up
  for (let i = 0; i < state.passengerCounts.adults; i += 1) {
    placeholders.push({
      IsLead: i === 0,
      Type: 'Adult',
      Title: 'Mr',
      DateOfBirth: adultDob,
      Forename: `Placeholder Passenger ${i}`,
      Surname: 'PLACEHOLDER',
    });
  }

  for (let i = 0; i < state.passengerCounts.children; i += 1) {
    placeholders.push({
      IsLead: false,
      Type: 'Child',
      Title: 'Mr',
      DateOfBirth: new Date(),
      Forename: `Placeholder Child ${i}`,
      Surname: 'PLACEHOLDER',
    });
  }

  const passengers = await Promise.all(
    placeholders.map((p) => api.createPassenger(bookingId, p)),
  );

  commit('setPassengers', passengers.map((p) => ({
    id: p.Id,
    forename: '',
    surname: '',
    type: p.Type,
    title: '',
    dateOfBirth: NaN,
    gender: 'NotSet',
    isMember: false,
  })));

  // Run availability search
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const searchId = await api.availabilitySearch(bookingId, state.tourCode, state.departureDate!);
  commit('setSearchId', searchId);

  // Get the results
  const departures = (await api.getDepartures(bookingId, searchId));

  if (departures.length === 0) {
    commit('setError', new Error('No available departure found for given criteria'));
    commit('setProcessing', false);
    return;
  }

  // Find the correct departure...
  const departure = departures.find((d) => d.Reference === state.tourCode);

  if (!departure) {
    commit('setError', new Error('Expected tour is not available'));
    return;
  }

  commit('setDeparture', departure);

  const accommodation = (await api.getAccommodation(bookingId, searchId, departure.Id));
  commit('setAccommodation', accommodation);

  const extras = (await api.getExtras(bookingId, searchId, departure.Id));
  commit('setExtras', extras);

  const bookingExtra = extras.find((e) => e.Reference === state.extraCode);

  if (!bookingExtra || bookingExtra.InventoryDetails.Available < state.passengers.length) {
    const error = new Error('Not enough spaces available for the selected activity');
    document.dispatchEvent(new CustomEvent('backendError', { detail: error }));
    return;
  }

  if (!extras.every((e) => !e.IsMandatory || e.InventoryDetails.Available >= passengers.length)) {
    const detail = new Error('Not enough spaces on one or more tour services');
    document.dispatchEvent(new CustomEvent('backendError', { detail }));
    return;
  }

  const selectedExtras = extras.filter(
    (e) => e.IsMandatory
      || (e.Reference === state.extraCode && e.Reference !== ''),
  ).map((e) => e.Id);

  commit('setSelectedExtras', selectedExtras);

  const flights = (await api.getFlights(bookingId, searchId, departure.Id));
  commit('setFlights', flights);

  const roomCounts: Record<string, number> = {};

  accommodation.forEach((r) => { roomCounts[r.Id] = 0; });
  commit('setRoomCounts', roomCounts);

  // Set the price
  commit('setTotalPrice', departure.Pricing.TotalPrice.Value);

  // Advance step
  commit('setStep', BookingStep.PASSENGER_DETAILS);

  commit('setProcessing', false);
}

async function savePassengers(bookingId: number, passengers: Passenger[]) {
  const updates = passengers.map((p): ReservationUpdateOperation[] => [
    {
      op: 'replace',
      path: '/Title',
      value: p.title,
    },

    {
      op: 'replace',
      path: '/Forename',
      value: p.forename,
    },

    {
      op: 'replace',
      path: '/Surname',
      value: p.surname,
    },

    {
      op: 'replace',
      path: '/DateOfBirth',
      value: new Date(p.dateOfBirth).toISOString(),
    },

    {
      op: 'replace',
      path: '/Gender',
      value: p.gender,
    },
  ]);

  await Promise.all(passengers.map((p, idx) => api.updatePassenger(bookingId, p.id, updates[idx])));
}

async function saveLeadPassenger(bookingId: number, passenger: Passenger, profile: LeadPassenger) {
  const req: CreateCustomerRequest = {
    Forename: passenger.forename,
    Surname: passenger.surname,
    Title: passenger.title,
    EmailAddress: profile.email,
    Gender: passenger.gender,
    DateOfBirth: new Date(passenger.dateOfBirth),
    TypeId: passenger.isMember ? CustomerType.Member : CustomerType.NotMember,
  };

  const newContact: CustomerContact = {
    Forename: passenger.forename,
    Surname: passenger.surname,
    Title: passenger.title,
    PersonalEmail: profile.email,
    PersonalMobile: profile.telephone,
    Address0: profile.address.address1,
    Address1: profile.address.address2,
    TownCity: profile.address.city,
    Country: profile.address.country,
    PostCode: profile.address.postcode,
    Type: 'Primary',
  };

  const { customer, contact } = await api.setupCustomer(
    bookingId, { customer: req, contact: newContact },
  );

  return { customer, contact };
}

async function save({ commit, state }: { commit: Function; state: BookingState }) {
  if (api.token === undefined) {
    api.token = state.token;
  }

  commit('setProcessing', true);

  await savePassengers(state.id, state.passengers);
  const rooming = getRooming(state);

  // Save the passenger notes
  await Promise.all(state.passengers.map((p: Passenger) => api.addPassengerNote(
    state.id,
    p.id,
    'SpecialRequest',
    'Special Requests from Online Booking',
    p.specialRequests || '',
  )));

  // Set the lead passenger's profile and then update the booking with their customer ID
  const { contact } = await saveLeadPassenger(
    state.id, state.passengers[0], state.leadPassenger,
  );

  commit('setContactId', contact.Id);

  if (rooming.length > 0) {
    const services: ServiceAssignmentRequest = {
      Accommodations: rooming,
    };

    if (state.includeDefaultFlights) {
      services.FlightGroups = state.flights.filter((f) => f.RateOption === 'Default').map(
        (f) => ({ ComponentId: f.Id, PassengerIds: state.passengers.map((p) => p.id) }),
      );
    }

    if (state.selectedExtras.length > 0) {
      const selected = state.extras.filter((extra) => state.selectedExtras.includes(extra.Id));

      services.Extras = selected.map((extra) => ({
        ComponentId: extra.Id,
        PassengerIds: state.passengers.map((p) => p.id),
      }));
    }

    const memberFee = state.extras.find((e) => e.Name === 'Non Member Fee');

    if (memberFee) {
      const nonMembers = state.passengers.filter((p) => !p.isMember).map((p) => p.id);

      if (nonMembers.length > 0) {
        if (!services.Extras) {
          services.Extras = [];
        }

        services.Extras.push({
          ComponentId: memberFee.Id,
          PassengerIds: nonMembers,
        });
      }
    }

    // Assign the services
    const departureId = (state.departure || { Id: '' }).Id;

    // If we somehow don't have the departure, that's just weird.
    if (departureId === '') {
      return;
    }

    await api.assignPassengers(state.id, state.searchId, departureId, services);

    // Book the tour
    await api.bookTour(state.id, departureId);

    // Finally update the tour data
    const booking = await api.getBooking(state.id);

    commit('setTotalPrice', booking.TotalPrice.Value);
    commit('setDepositPrice', booking.Deposit.Value);
    commit('setReference', booking.BookingReference);
    commit('setBalanceDue', booking.BalanceDue);
  }

  // Advance to the pre-payment stage
  commit('setStep', BookingStep.PREPAYMENT);

  commit('setProcessing', false);
}

async function pay({ commit, state }: { commit: Function; state: BookingState }) {
  if (!api.token) {
    api.token = state.token;
  }

  commit('setProcessing', true);

  const payment = await api.createPayment(state.id, state.paymentType, state.contactId);

  commit('setPaymentUrl', payment.Url);
  commit('setPaymentId', payment.Id);

  // Redirect the user to the payment page.
  if (window !== undefined) {
    commit('setProcessing', false);
    window.location.href = payment.Url;
  }
}

/**
 * Finalize a booking
 * @param commit
 * @param state
 */
async function finalize({ commit, state }: { commit: Function; state: BookingState }) {
  if (!api.token) {
    api.token = state.token;
  }

  commit('setProcessing', true);

  const payment = await api.getPayment(state.id, state.paymentId);

  if (payment.Status === '1' || payment.Status === '3') {
    try {
      await api.confirmBooking(state.id);
      await api.finalizePayment(state.id, state.paymentId);
      commit('setStatus', BookingStatus.CONFIRMED);
    } catch (error) {
      console.log('Booking confirmation did not succeed.');
      commit('setStatus', BookingStatus.FAILED_OTHER);
    }
  } else {
    // Payment did not succeed.
    commit('setStatus', BookingStatus.FAILED_PAYMENT);
  }

  const { BookingReference } = await api.getBooking(state.id);
  commit('setReference', BookingReference);
}

async function listComponents(
  { commit, state }: { commit: Function; state: BookingState },
): Promise<BookingComponentListEntry[]> {
  if (!api.token) {
    api.token = state.token;
  }
  const data = await api.listComponents(state.id);
  if (data.length > 0) {
    commit('setLoadingState', false);
  }
  return orderBy(data, (item) => item.Price.Value, ['desc']);
}

export default {
  initialize,
  save,
  pay,
  finalize,
  listComponents,
};
