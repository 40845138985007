















































import Component from 'vue-class-component';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { NavigationGuardNext, Route } from 'vue-router';
import { BookingStatus, BookingStep } from '@/store/modules/booking/state';
import ComponentList from '@/components/booking/ComponentList.vue';

@Component({
  components: { ComponentList },
  computed: {
    ...mapGetters('Bookings', ['status', 'reference', 'gtmProducts']),
    ...mapState('Bookings', ['totalPrice']),
  },
})
export default class BookingConfirmation extends Vue {
  status!: BookingStatus;

  reference!: string;

  gtmProducts!: object[];

  totalPrice!: number;

  /**
   * Router Guard: If the user attempts to visit this page without a confirmed
   * booking in the current app state, then bounce them back to the booking process
   * before loading the route.
   */
  beforeRouteEnter(_to: Route, _from: Route, next: NavigationGuardNext<Vue>): void {
    if (this.status !== BookingStatus.CONFIRMED) {
      next({ name: 'Book' });
    } else {
      next();
    }
  }

  mounted() {
    this.$gtm.trackEvent({
      ecommerce: {
        purchase: {
          products: this.gtmProducts,
          actionField: {
            id: this.reference,
            revenue: this.totalPrice,
          },
        },
      },
    });
  }
}
