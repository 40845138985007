var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_vm._l((_vm.$v.passengers.$each.$iter),function(passenger,key,$index){return _c('div',{key:passenger.id,staticClass:"zen-vue-app__content"},[_c('b-card',{staticClass:"p-3 pb-4 mb-3",attrs:{"id":'passenger-' + passenger.id}},[_c('h4',{staticClass:"card-title zen-title zen-title--secondary-light mb-4"},[_vm._v(" Passenger: "+_vm._s($index + 1)+" "),_c('span',{staticClass:"details"},[_vm._v(" ("+_vm._s(passenger.$model.type)+") ")])]),_c('b-form',[_c('div',{staticClass:"zen-radio-btns form-row form-group mb-4",class:{
            'form-group--error': passenger.title.$error
          },attrs:{"id":'title-' + $index}},[_c('label',{staticClass:"col-sm-4 col-lg-3 col-form-label",attrs:{"for":'title-' + $index}},[_vm._v(" Title* ")]),_c('div',{staticClass:"col-sm-8 col-lg-9"},[_vm._l((_vm.titles),function(title,key,index){return _c('b-form-radio',{key:index,attrs:{"name":"title-radios","value":title},model:{value:(passenger.title.$model),callback:function ($$v) {_vm.$set(passenger.title, "$model", $$v)},expression:"passenger.title.$model"}},[_vm._v(_vm._s(title))])}),(!passenger.title.required
                    && passenger.title.$dirty)?_c('b-form-invalid-feedback',[_vm._v(" Title is required ")]):_vm._e()],2)]),_c('div',{staticClass:"form-row form-group mb-4",class:{
            'form-group--error': passenger.forename.$error
          },attrs:{"id":'firstname-' + $index}},[_c('label',{staticClass:"col-sm-4 col-lg-3 col-form-label",attrs:{"for":'firstname-' + $index}},[_vm._v(" First Name* "),_c('p',{staticClass:"zen-text zen-text--default-lighter zen-text--text-xs mt-2 mb-0"},[_vm._v(" Please enter as per passport ")])]),_c('div',{staticClass:"col-sm-8 col-lg-9"},[_c('b-form-input',{attrs:{"required":"","autocomplete":"given-name","placeholder":"Please enter as per passport","state":passenger.forename.$dirty
                    ? !passenger.forename.$error : null},on:{"blur":function($event){return passenger.forename.$touch()}},model:{value:(passenger.forename.$model),callback:function ($$v) {_vm.$set(passenger.forename, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"passenger.forename.$model"}}),(!passenger.forename.required
                    && passenger.forename.$dirty)?_c('b-form-invalid-feedback',[_vm._v(" First name is required ")]):_vm._e()],1)]),_c('div',{staticClass:"form-row form-group mb-4",class:{
            'form-group--error': passenger.surname.$error
          },attrs:{"id":'lastname-' + $index}},[_c('label',{staticClass:"col-sm-4 col-lg-3 col-form-label",attrs:{"for":'lastname-' + $index}},[_vm._v(" Last/Surname* "),_c('p',{staticClass:"zen-text zen-text--default-lighter zen-text--text-xs mt-2 mb-0"},[_vm._v(" Please enter as per passport ")])]),_c('div',{staticClass:"col-sm-8 col-lg-9"},[_c('b-form-input',{attrs:{"autocomplete":"family-name","state":passenger.surname.$dirty ?
                      !passenger.surname.$error : null,"required":"","placeholder":"Please enter as per passport"},on:{"blur":function($event){return passenger.surname.$touch()}},model:{value:(passenger.surname.$model),callback:function ($$v) {_vm.$set(passenger.surname, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"passenger.surname.$model"}}),(!passenger.surname.required
              && passenger.surname.$dirty)?_c('b-form-invalid-feedback',[_vm._v(" Last name is required ")]):_vm._e()],1)]),($index === 0)?_c('div',{staticClass:"form-row form-group mb-4",class:{
            'form-group--error': _vm.$v.leadPassenger.email.$error
          },attrs:{"id":'email-' + $index}},[_c('label',{staticClass:"col-sm-4 col-lg-3 col-form-label",attrs:{"for":'email-' + $index}},[_vm._v(" Email Address* "),_c('p',{staticClass:"zen-text zen-text--default-lighter zen-text--text-xs mt-2 mb-0"},[_vm._v(" Required for booking confirmation ")])]),_c('div',{staticClass:"col-sm-8 col-lg-9"},[_c('b-form-input',{attrs:{"state":_vm.$v.leadPassenger.email.$dirty ?
                      !_vm.$v.leadPassenger.email.$error : null,"autocomplete":"email","type":"email","placeholder":"Email address"},model:{value:(_vm.$v.leadPassenger.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.leadPassenger.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.leadPassenger.email.$model"}}),(!_vm.$v.leadPassenger.email.email
                    && _vm.$v.leadPassenger.email.$dirty)?_c('b-form-invalid-feedback',[_vm._v(" Must be a valid e-mail address ")]):_vm._e(),(!_vm.$v.leadPassenger.email.required
                    && _vm.$v.leadPassenger.email.$dirty)?_c('b-form-invalid-feedback',[_vm._v(" E-Mail address is required ")]):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"form-row form-group mb-4",class:{
            'form-group--error': passenger.dateOfBirth.$error
          },attrs:{"id":'dob-' + $index}},[_c('label',{staticClass:"col-sm-4 col-lg-3 col-form-label",attrs:{"for":'dob-' + $index}},[_vm._v(" Date of Birth* ")]),_c('div',{staticClass:"col-sm-8 col-lg-9"},[($index === 0)?_c('lead-date-of-birth',{attrs:{"index":$index},on:{"eventValidate":_vm.validate},model:{value:(passenger.dateOfBirth.$model),callback:function ($$v) {_vm.$set(passenger.dateOfBirth, "$model", $$v)},expression:"passenger.dateOfBirth.$model"}}):_c('date-of-birth',{attrs:{"index":$index},model:{value:(passenger.dateOfBirth.$model),callback:function ($$v) {_vm.$set(passenger.dateOfBirth, "$model", $$v)},expression:"passenger.dateOfBirth.$model"}})],1)]),_c('div',{staticClass:"zen-radio-btns form-row form-group mb-4",class:{
            'form-group--error': passenger.gender.$error
          },attrs:{"id":'gender-' + $index}},[_c('label',{staticClass:"col-sm-4 col-lg-3 col-form-label",attrs:{"for":'gender-' + $index}},[_vm._v(" Gender* ")]),_c('div',{staticClass:"col-sm-8 col-lg-9"},[_vm._l((_vm.genders),function(gender,key,index){return _c('b-form-radio',{key:index,attrs:{"name":"gender-radios","value":gender},model:{value:(passenger.gender.$model),callback:function ($$v) {_vm.$set(passenger.gender, "$model", $$v)},expression:"passenger.gender.$model"}},[_vm._v(_vm._s(gender))])}),(!passenger.gender.required
                    && passenger.gender.$dirty)?_c('b-form-invalid-feedback',[_vm._v(" Gender is required ")]):_vm._e()],2)]),_c('div',{staticClass:"form-row form-group mb-4",attrs:{"id":'member-' + $index}},[_c('label',{staticClass:"col-sm-4 col-lg-3 col-form-label",attrs:{"for":'member-' + $index}},[_vm._v(" Are you a Member? "),_c('p',{staticClass:"zen-text zen-text--default-lighter zen-text--text-xs mt-2 mb-0"},[_vm._v(" Non-members can book for just £30 extra details on how to become a member please "),_c('a',{staticClass:"zen-link zen-link--default-underline",attrs:{"href":"https://www.hfholidays.co.uk/membership","target":"_blank"}},[_vm._v("click here")])])]),_c('div',{staticClass:"col-sm-8 col-lg-9"},[_c('div',[_c('b-form-checkbox',{attrs:{"id":'member-check' + $index,"name":'member-check' + $index},model:{value:(passenger.isMember.$model),callback:function ($$v) {_vm.$set(passenger.isMember, "$model", $$v)},expression:"passenger.isMember.$model"}})],1)])]),_c('div',{staticClass:"form-row form-group mb-4",attrs:{"id":'requests-' + $index}},[_c('label',{staticClass:"col-sm-4 col-lg-3 col-form-label",attrs:{"for":'requests-' + $index}},[_vm._v(" Special Requests / Requirements ")]),_c('div',{staticClass:"col-sm-8 col-lg-9"},[_c('b-form-textarea',{attrs:{"id":'requests-textarea' + $index,"state":passenger.specialRequests.$dirty ? !passenger.specialRequests.$error : null,"placeholder":"For example dietary requirements / medical conditions. ","size":"lg"},model:{value:(passenger.specialRequests.$model),callback:function ($$v) {_vm.$set(passenger.specialRequests, "$model", $$v)},expression:"passenger.specialRequests.$model"}})],1)])])],1)],1)}),_c('div',{staticClass:"d-flex"},[_c('prev-step'),_c('next-step',{attrs:{"valid":_vm.valid && _vm.leadValidation}})],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"zen-vue-app__content text-center"},[_c('h2',{staticClass:"zen-title zen-title--hr-primary"},[_vm._v(" Traveller Information ")])])}]

export { render, staticRenderFns }