






















































































import Component, { mixins } from 'vue-class-component';

import { mapGetters } from 'vuex';
import { Models } from 'tigerbay';
import { BIcon, BIconExclamationCircleFill, BIconTrash } from 'bootstrap-vue';
import { Watch } from 'vue-property-decorator';
import AvailabilityCheck from '@/components/booking/AvailabilityCheck.vue';
import BookingSteps from '@/components/booking/BookingSteps.vue';
import BookingDetails from '@/components/booking/BookingDetails.vue';
import Passengers from '@/components/booking/Passengers.vue';
import RoomSelection from '@/components/booking/RoomSelection.vue';
import RoomingAssignment from '@/components/booking/RoomingAssignment.vue';
import ServicesSelection from '@/components/booking/ServicesSelection.vue';
import CustomerProfile from '@/components/booking/CustomerProfile.vue';
import PrePayment from '@/components/booking/PrePayment.vue';
import ErrorHandling from '@/mixins/errorHandling';
import { BookingStep } from '@/store/modules/booking/state';

@Component({
  components: {
    AvailabilityCheck,
    BookingSteps,
    BookingDetails,
    Passengers,
    RoomSelection,
    RoomingAssignment,
    ServicesSelection,
    CustomerProfile,
    PrePayment,
    BIcon,
    BIconExclamationCircleFill,
    BIconTrash,
  },
  computed: {
    ...mapGetters('Bookings', ['step', 'initialized', 'processing', 'expired', 'departure', 'expiresIn', 'expiresHandle']),
  },
})
export default class Book extends mixins(ErrorHandling) {
  expired!: boolean;

  departure!: Models.Tours.Departure | null;

  expiresHandle!: number | null;

  expiresIn!: number | null;

  step!: BookingStep;

  get promptToReset() {
    let dc = false;

    if (this.$route.query.departure !== ''
      && this.departure !== null) {
      const date = this.departure.Duration.From as unknown as string;
      dc = this.$route.query.departure !== date.split('T')[0];
    }

    return dc;
  }

  reset() {
    this.$store.commit('Bookings/reset');
    this.backendError = null;
    this.loadParams();
  }

  mounted() {
    document.title = 'Book your HF Holiday';

    if (this.promptToReset) {
      this.reset();
    } else {
      if (this.expiresHandle !== null && this.expiresIn !== null) {
        this.$store.commit('Bookings/setExpiresHandle', setTimeout(() => {
          this.$store.commit('Bookings/setExpired', true);
        }, this.expiresIn));
      }
      this.loadParams();
    }
  }

  loadParams(): void {
    if (typeof this.$route.query.tour === 'string') {
      this.$store.commit('Bookings/setTourCode', this.$route.query.tour);
    }

    if (typeof this.$route.query.departure === 'string') {
      this.$store.commit('Bookings/setDepartureDate', new Date(this.$route.query.departure));
    }

    if (typeof this.$route.query.extra === 'string') {
      this.$store.commit('Bookings/setExtraCode', this.$route.query.extra);
    }
  }

  @Watch('step')
  onStepChange(step: BookingStep) {
    if (this.$gtm) {
      this.$gtm.trackEvent({
        event: 'virtualPageView',
        pageName: step,
      });
    }
  }
}
