



























































































































































































































































import { Passenger } from 'tigerbay/lib/models/reservations';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import {
  required,
  email,
  maxLength,
} from 'vuelidate/lib/validators';
import { mapGetters, mapMutations, mapState } from 'vuex';
import PrevStep from '@/components/booking/PrevStep.vue';
import NextStep from '@/components/booking/NextStep.vue';
import DateOfBirth from '@/components/elements/DateOfBirth.vue';
import LeadDateOfBirth from '@/components/elements/LeadDateOfBirth.vue';
import { LeadPassenger } from '@/store/modules/booking/state';

const specialRequestsMaxLength = maxLength(255);

Component.registerHooks(['validations']);
@Component({
  components: {
    LeadDateOfBirth,
    DateOfBirth,
    PrevStep,
    NextStep,
  },
  validations: {
    leadPassenger: {
      email: { required, email },
    },
    passengers: {
      $each: {
        title: { required },
        forename: { required },
        surname: { required },
        dateOfBirth: { required },
        gender: { required },
        isMember: { },
        specialRequests: { specialRequestsMaxLength },
      },
    },
  },
  name: 'passengers',
  computed: {
    ...mapState('Bookings', ['leadPassenger']),
    ...mapGetters('Bookings', ['passengers']),
  },

  methods: {
    ...mapMutations('Bookings', ['nextStep']),
  },
})
export default class Passengers extends Vue {
  leadValidation = false

  get titles(): string[] {
    return ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr'];
  }

  get genders(): string[] {
    return ['Male', 'Female'];
  }

  get valid(): boolean {
    return !this.$v.$invalid;
  }

  validate(valid: boolean) {
    this.leadValidation = valid;
  }

  @Watch('passengers', { deep: true })
  onPassengersUpdated(value: Array<Passenger>) {
    this.$store.commit('Bookings/setPassengers', value);
  }

  @Watch('leadPassenger', { deep: true })
  onLeadPassengerUpdated(value: LeadPassenger) {
    this.$store.commit('Bookings/setLeadPassenger', value);
  }
}
