





import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { BookingStatus } from '@/store/modules/booking/state';
import ErrorHandling from '@/mixins/errorHandling';

@Component({
  computed: {
    ...mapGetters('Bookings', ['status', 'id']),
  },
})
export default class PayBooking extends mixins(ErrorHandling) {
  status!: BookingStatus;

  id!: number;

  mounted() {
    this.redirect();
  }

  private redirect(): void {
    switch (this.status) {
      case BookingStatus.CONFIRMED:
        this.$router.push(`/book/${this.id}/confirmation`);
        break;
      case BookingStatus.FAILED_PAYMENT:
      case BookingStatus.FAILED_OTHER:
        this.$router.push(`/book/${this.id}/commiseration`);
        break;
      default:
        this.$store.dispatch('Bookings/finalize');
        break;
    }
  }

  @Watch('status')
  statusChanged(value: BookingStatus): void {
    this.$emit('statusChanged', value);
    this.redirect();
  }
}
