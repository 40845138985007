






























































import { CacheSearchRequest } from 'tigerbay/lib/models/cache';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import Component from 'vue-class-component';
import { BIcon, BIconSearch } from 'bootstrap-vue';
import { required } from 'vuelidate/lib/validators';

@Component({
  name: 'tour-search-form',
  components: { BIcon, BIconSearch },

  computed: {
    ...mapGetters('Tours', ['tours', 'count']),
  },

  validations: {
    tourCode: { required },
  },
})
export default class TourSearchForm extends Vue {
  loading = false;

  startDate: Date = new Date();

  endDate: Date | null = null;

  tourCode = '';

  fromPrice = 0;

  toPrice = 0;

  submit() {
    this.$v.$touch();

    if (this.$v.$anyError) {
      return;
    }

    this.loading = true;
    const searchParams: CacheSearchRequest = {
      serviceDateRange: {
        from: this.startDate,
        to: this.endDate || undefined,
      },
      tourCode: this.tourCode || undefined,
      priceRange: {
        from: this.fromPrice || undefined,
        to: this.toPrice || undefined,
      },
    };

    this.$emit('submit', searchParams);
  }
}
